// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tjanster-custom-solutions-js": () => import("../src/pages/tjanster/custom-solutions.js" /* webpackChunkName: "component---src-pages-tjanster-custom-solutions-js" */),
  "component---src-pages-tjanster-digital-experience-js": () => import("../src/pages/tjanster/digital-experience.js" /* webpackChunkName: "component---src-pages-tjanster-digital-experience-js" */),
  "component---src-pages-tjanster-identity-js": () => import("../src/pages/tjanster/identity.js" /* webpackChunkName: "component---src-pages-tjanster-identity-js" */),
  "component---src-pages-tjanster-integration-js": () => import("../src/pages/tjanster/integration.js" /* webpackChunkName: "component---src-pages-tjanster-integration-js" */)
}

